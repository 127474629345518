import React, { useState, useEffect, useContext, useRef } from 'react';
import './earn.css';
import coinImage from '../../assets/images/coin.png';
import closeIcon from '../../assets/icons/close.svg';
import thalaTask from '../../assets/images/earn/thala_task.png';
import dailyReward from '../../assets/images/earn/daily_reward.png';
import { dailyTasks, taskList } from './earn_model';
import earnApi from './earn_api';
import { MusicContext } from '../../music_provider';
import { GameContext } from '../game/game_provider.js';
import tickSquareIcon from '../../assets/icons/tick_square.svg';
import closeSquareIcon from '../../assets/icons/close_square.svg';
import CoinAnimation from './coin_animation'; // 或者 CoinAnimationCanvas
import taskSound from '../../assets/sound_effect/copy.mp3';


const TASK_TYPES = {
    MODAL: 'MODAL',
    COUNTDOWN: 'COUNTDOWN',
    SINGLE_TASK: 'SINGLE_TASK',
    DOWNLOAD_SIGNUP: 'DOWNLOAD_SIGNUP',
};

const getTaskActionType = (taskTitle) => {
    const taskActionMap = {
        'Join our TG Channel': { api: 'join_tg_channel', provider: 'is_join_tg_channel', failureMessage: 'Not Joined' },
        'Follow us on Facebook': { api: 'follow_fb', provider: 'is_follow_FB', failureMessage: 'Not Followed' },
        'Follow us on X': { api: 'follow_x', provider: 'is_follow_X', failureMessage: 'Not Followed' },
        'Follow us on Instagram': { api: 'follow_ig', provider: 'is_follow_IG', failureMessage: 'Not Followed' },
    };

    return taskActionMap[taskTitle] || { api: '', provider: '', failureMessage: 'Task Failed' };
};

const TaskCard = ({ title, amount, url, onStart, taskType, isCompleted, onTaskSuccess, onCoinAnimation }) => {
    const { setUser } = useContext(GameContext);
    const [buttonState, setButtonState] = useState(isCompleted ? 'Done' : 'Start');
    const [countdown, setCountdown] = useState(5);
    const [isDisabled, setIsDisabled] = useState(isCompleted);
    const [apiStatus, setApiStatus] = useState('idle');
    const [isCountdownFinished, setIsCountdownFinished] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const [failureIcon, setFailureIcon] = useState(closeSquareIcon);

    const handleClick = async (event) => {
        event.preventDefault();

        const { api: taskActionTypeForAPI, provider: taskActionTypeForProvider, failureMessage } = getTaskActionType(title);

        if (taskType === TASK_TYPES.MODAL) {
            onStart(title, { setButtonState, setIsDisabled });
        } else if (taskType === TASK_TYPES.SINGLE_TASK) {
            if (buttonState === 'Start') {
                window.open(url, '_blank');
                setButtonState('Check');
            } else if (buttonState === 'Check') {
                startCountdown();

                try {
                    const response = await earnApi.completeSingleTask(taskActionTypeForAPI);
                    if (response && response.code === 0 && response.result === "success") {
                        setUser((prevUser) => {
                            const updatedTasks = { ...prevUser.tasks };
                            updatedTasks.single_tasks[taskActionTypeForProvider] = true;
                            const newTokenAmount = prevUser.token_amount + parseInt(amount.replace(/\D/g, ''), 10);

                            return {
                                ...prevUser,
                                tasks: updatedTasks,
                                token_amount: newTokenAmount
                            };
                        });
                        setApiStatus('success');
                    } else {
                        setFailureMessage(failureMessage);
                        setFailureIcon(closeSquareIcon);
                        setApiStatus('failed');
                    }
                } catch (error) {
                    console.error('Failed to complete single task:', error);
                    setFailureMessage(failureMessage);
                    setFailureIcon(closeSquareIcon);
                    setApiStatus('failed');
                }
            }
        } else if (taskType === TASK_TYPES.DOWNLOAD_SIGNUP) {
            if (buttonState === 'Start') {
                window.open(url, '_blank');
                setButtonState('Check');
            } else if (buttonState === 'Check') {
                onStart(title, { setButtonState, setIsDisabled });
            }
        } else if (taskType === TASK_TYPES.COUNTDOWN) {
            startCountdown(() => setButtonState('Done'));
        } else {
            console.error('Unknown task type');
        }
    };

    const startCountdown = () => {
        setIsDisabled(true);
        setButtonState('Countdown');
        setCountdown(5);
        setIsCountdownFinished(false);
        setApiStatus('idle');

        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown > 1) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(interval);
                    setIsCountdownFinished(true);

                    if (apiStatus === 'success') {
                        setButtonState('Done');
                    } else if (apiStatus === 'failed') {
                        setButtonState('Start');
                    }
                    setIsDisabled(false);
                    return 0;
                }
            });
        }, 1000);
    };

    useEffect(() => {
        if (isCountdownFinished) {
            if (apiStatus === 'success') {
                setButtonState('Done');
                onTaskSuccess('Successful!', tickSquareIcon);
                if (onCoinAnimation) {
                    onCoinAnimation();
                }
            } else if (apiStatus === 'failed') {
                setButtonState('Start');
                onTaskSuccess(failureMessage, failureIcon);
            }

            setApiStatus('idle');
            setIsCountdownFinished(false);
        }
    }, [apiStatus, isCountdownFinished, onTaskSuccess, failureMessage, failureIcon, onCoinAnimation]);

    return (
        <div className="task-card">
            <div className="task-info">
                <div className="task-title">{title}</div>
                <div className="task-amount">
                    <img src={coinImage} alt="Coin" className="coin-icon" />
                    <span>{amount}</span>
                </div>
            </div>
            <button
                className={`task-button ${buttonState === 'Done' ? 'done' : ''}`}
                onClick={handleClick}
                disabled={isDisabled}
            >
                {buttonState === 'Countdown' ? `${countdown}s` : buttonState}
            </button>
        </div>
    );
};

const getTaskType = (taskTitle) => {
    switch (taskTitle) {
        case 'Daily reward':
        case 'Post on Thala':
            return TASK_TYPES.MODAL;
        case 'Download and Sign Up for Thala':
            return TASK_TYPES.DOWNLOAD_SIGNUP;
        case 'Join our TG Channel':
        case 'Follow us on Facebook':
        case 'Follow us on X':
        case 'Follow us on Instagram':
            return TASK_TYPES.SINGLE_TASK;
        default:
            return TASK_TYPES.COUNTDOWN;
    }
};

const Earn = () => {
    const { user, setUser } = useContext(GameContext);
    const { setMusic, isMusicPlaying } = useContext(MusicContext);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [currentTask, setCurrentTask] = useState(null);
    const [showTaskPopup, setShowTaskPopup] = useState(false);
    const [taskPopupMessage, setTaskPopupMessage] = useState('');
    const [popupIcon, setPopupIcon] = useState(tickSquareIcon);
    const [inputValue, setInputValue] = useState('');
    const [redeemButtonDisabled, setRedeemButtonDisabled] = useState(true);
    const [showCoinAnimation, setShowCoinAnimation] = useState(false);

    const audioRef = useRef(null);

    const currentTimestamp = Math.floor(Date.now() / 1000);
    const isSameDay = (timestamp1, timestamp2) => {
        const date1 = new Date(timestamp1 * 1000);
        const date2 = new Date(timestamp2 * 1000);
        return date1.getUTCFullYear() === date2.getUTCFullYear() &&
            date1.getUTCMonth() === date2.getUTCMonth() &&
            date1.getUTCDate() === date2.getUTCDate();
    };

    const completedTasks = {
        "is_sign_in": isSameDay(user.tasks.daily_tasks.sign_in_data.last_sign_in_date, currentTimestamp),
        "is_follow_FB": user.tasks.single_tasks.is_follow_FB,
        "is_follow_IG": user.tasks.single_tasks.is_follow_IG,
        "is_follow_X": user.tasks.single_tasks.is_follow_X,
        "is_join_tg_channel": user.tasks.single_tasks.is_join_tg_channel,
        "is_register_thala": user.tasks.single_tasks.is_register_thala,
        "is_post_on_thala": isSameDay(user.tasks.daily_tasks.thala_post_task.last_post_date, currentTimestamp)
    };

    const consecutiveDays = user.tasks.daily_tasks.sign_in_data.consecutive_days;

    useEffect(() => {
        setMusic(require('../../assets/bgm/invite_friends.mp3'));
    }, [setMusic]);

    useEffect(() => {
        setRedeemButtonDisabled(inputValue.trim() === '');
    }, [inputValue]);

    useEffect(() => {
        if (showTaskPopup) {
            if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
                const vibratePattern = ['medium', 'medium', 'medium', 'medium', 'medium'];
                vibratePattern.forEach((type, index) => {
                    setTimeout(() => {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred(type);
                    }, index * 100);
                });
            }
            if (isMusicPlaying && audioRef.current) {
                audioRef.current.play();
            }
        }
    }, [showTaskPopup, isMusicPlaying]);

    const handleTaskStart = (taskTitle, taskReference) => {
        setModalContent(taskTitle);
        setCurrentTask(taskReference);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setInputValue('');
    };

    const handleClearClick = () => {
        setInputValue('');
    };

    let originalPosition = '';
    let originalHeight = '';
    let originalBottom = '';
    let originalOverflow = '';
    let originalContentAlignment = '';
    let originalContentJustify = '';
    let originalContentPaddingTop = '';

    const handleInputFocus = () => {
        const modalContainer = document.querySelector('.task-modal-container');
        const modalContent = document.querySelector('.task-modal-content');
        if (modalContainer && modalContent) {
            originalPosition = modalContainer.style.position;
            originalHeight = modalContainer.style.height;
            originalBottom = modalContainer.style.bottom;
            originalOverflow = modalContainer.style.overflow;

            originalContentAlignment = modalContent.style.alignItems;
            originalContentJustify = modalContent.style.justifyContent;
            originalContentPaddingTop = modalContent.style.paddingTop;

            modalContainer.style.position = 'fixed';
            modalContainer.style.bottom = '0';
            modalContainer.style.height = 'calc(100% - 80px)';
            modalContainer.style.overflow = 'auto';

            modalContent.style.alignItems = 'center';
            modalContent.style.justifyContent = 'flex-start';
            modalContent.style.paddingTop = '40px';

            setTimeout(() => {
                modalContainer.scrollTop = modalContainer.scrollHeight;
            }, 0);
        }
    };

    const handleInputBlur = () => {
        const modalContainer = document.querySelector('.task-modal-container');
        const modalContent = document.querySelector('.task-modal-content');
        if (modalContainer && modalContent) {
            modalContainer.style.position = originalPosition;
            modalContainer.style.height = originalHeight;
            modalContainer.style.bottom = originalBottom;
            modalContainer.style.overflow = originalOverflow;

            modalContent.style.alignItems = originalContentAlignment;
            modalContent.style.justifyContent = originalContentJustify;
            modalContent.style.paddingTop = originalContentPaddingTop;

            modalContainer.scrollTop = 0;
        }
    };

    const handleRedeemClick = async () => {
        try {
            let response;

            if (modalContent === 'Download and Sign Up for Thala') {
                response = await earnApi.completeSingleTask('register_thala', inputValue);
            } else {
                response = await earnApi.verifyTaskWithCode(inputValue);
            }

            if (response && response.code === 0 && response.result === 'success') {
                handleCloseModal();
                if (currentTask) {
                    currentTask.setButtonState('Done');
                    currentTask.setIsDisabled(true);
                }
                setUser((prevUser) => {
                    const updatedTasks = { ...prevUser.tasks };
                    let newTokenAmount = prevUser.token_amount;
                    if (modalContent === 'Download and Sign Up for Thala') {
                        updatedTasks.single_tasks.is_register_thala = true;
                        newTokenAmount += parseInt(taskList.find(task => task.title === 'Download and Sign Up for Thala').amount.replace(/\D/g, ''), 10);
                    } else if (modalContent === 'Post on Thala') {
                        updatedTasks.daily_tasks.thala_post_task.last_post_date = Math.floor(Date.now() / 1000);
                        newTokenAmount += parseInt(dailyTasks.find(task => task.title === 'Post on Thala').amount.replace(/\D/g, ''), 10);
                    }

                    return {
                        ...prevUser,
                        tasks: updatedTasks,
                        token_amount: newTokenAmount,
                    };
                });

                setTaskPopupMessage('Successful!');
                setPopupIcon(tickSquareIcon);

                setShowTaskPopup(true);
                setTimeout(() => {
                    setShowTaskPopup(false);
                }, 3000);

                setShowCoinAnimation(true);
                setTimeout(() => {
                    setShowCoinAnimation(false);
                }, 600);

            } else {
                handleCloseModal();
                setTaskPopupMessage('Invalid Code');
                setPopupIcon(closeSquareIcon);

                setShowTaskPopup(true);
                setTimeout(() => {
                    setShowTaskPopup(false);
                }, 3000);

            }
        } catch (error) {
            console.error('Error during task verification:', error);
        }
    };

    const handleSignInClick = async () => {
        try {
            const response = await earnApi.signInDailyTask();
            if (response && response.code === 0 && response.result === 'success') {
                const currentTimestamp = Math.floor(Date.now() / 1000);

                setUser((prevUser) => {
                    const updatedTasks = { ...prevUser.tasks };
                    const previousConsecutiveDays = updatedTasks.daily_tasks.sign_in_data.consecutive_days;

                    updatedTasks.daily_tasks.sign_in_data.last_sign_in_date = currentTimestamp;
                    updatedTasks.daily_tasks.sign_in_data.consecutive_days = previousConsecutiveDays + 1;
                    const rewardAmount = dailyTasks[0].amounts[previousConsecutiveDays % 7];
                    const newTokenAmount = prevUser.token_amount + rewardAmount;

                    return {
                        ...prevUser,
                        tasks: updatedTasks,
                        token_amount: newTokenAmount,
                    };
                });

                setTaskPopupMessage('Sign-In Successful!');
                setPopupIcon(tickSquareIcon);
                setShowTaskPopup(true);
                setTimeout(() => {
                    setShowTaskPopup(false);
                }, 3000);

                setShowCoinAnimation(true);
                setTimeout(() => {
                    setShowCoinAnimation(false);
                }, 600);

            } else {
                setTaskPopupMessage('Sign-In failed');
                setPopupIcon(closeSquareIcon);
                setShowTaskPopup(true);
                setTimeout(() => {
                    setShowTaskPopup(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Error signing in daily task:', error);
        }
    };

    const getDailyRewardAmount = () => {
        const day = consecutiveDays !== null ? consecutiveDays : (new Date().getDate() % 7);
        let currentDayIndex = day === 0 || day === 7 ? 0 : day;
        return `+${dailyTasks[0].amounts[currentDayIndex]}`;
    };

    const getModalClassName = () => {
        return `task-modal-container ${modalContent === 'Post on Thala' || modalContent === 'Download and Sign Up for Thala' ? 'thala-modal' : ''} ${showModal ? 'show' : ''}`;
    };

    const getModalContent = () => {
        const isSignInDone = completedTasks.is_sign_in;

        const getRewardBoxClass = (index) => {
            const isTodaySignable = index === consecutiveDays && !isSignInDone;
            if (isTodaySignable) {
                return 'reward-box current-day';
            } else if (index < consecutiveDays) {
                return 'reward-box signed-day';
            } else {
                return 'reward-box';
            }
        };

        if (modalContent === 'Download and Sign Up for Thala' || modalContent === 'Post on Thala') {
            return (
                <div>
                    <div className="task-modal-image-container">
                        <img src={thalaTask} alt="Thala Task" />
                    </div>
                    <div className="task-modal-title">
                        {modalContent}
                    </div>
                    <div className="task-input-container">
                        {!inputValue && (
                            <div className="task-placeholder">
                                Paste the code here
                            </div>
                        )}
                        <input
                            type="text"
                            className="task-input"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            style={{ color: inputValue ? 'white' : '#666' }}
                        />
                        {inputValue && (
                            <button className="task-clear-button" onClick={handleClearClick}>
                                Clear
                            </button>
                        )}
                    </div>
                    <button
                        className="redeem-button"
                        onClick={handleRedeemClick}
                        disabled={redeemButtonDisabled}
                    >
                        Redeem
                    </button>
                </div>
            );
        }


        if (modalContent === 'Daily reward') {
            return (
                <div>
                    <div className="task-modal-image-container">
                        <img src={dailyReward} alt="Daily Reward" />
                    </div>
                    <div className="task-modal-title">
                        {modalContent}
                    </div>
                    <div className="task-modal-description">
                        Daily sign in to steadily earn coins—don't miss a single day!
                    </div>
                    <div className="rewards-container-wrapper">
                        <div className="rewards-container">
                            {dailyTasks[0].amounts.map((amount, index) => (
                                <div key={index} className={getRewardBoxClass(index)}>
                                    <div className="reward-day">Day {index + 1}</div>
                                    <img src={coinImage} alt="Coin" className="reward-coin" />
                                    <div className="reward-amount">+{amount}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button
                        className={`redeem-button ${isSignInDone ? 'done-button' : ''}`}
                        onClick={isSignInDone ? null : handleSignInClick}
                        disabled={isSignInDone}
                    >
                        {isSignInDone ? 'Done' : 'Sign in'}
                    </button>
                </div>
            );
        }

        return null;
    };

    const handleShowCoinAnimation = () => {
        setShowCoinAnimation(true);
        setTimeout(() => {
            setShowCoinAnimation(false);
        }, 600);
    };
    return (
        <div className="earn">
            <div className="scroll-container">
                <div className="section-title">Daily tasks</div>
                {dailyTasks.map((task, index) => (
                    <TaskCard
                        key={index}
                        title={task.title}
                        amount={task.title === 'Daily reward' ? getDailyRewardAmount() : task.amount}
                        url={task.url}
                        onStart={(title, reference) => handleTaskStart(title, reference)}
                        taskType={getTaskType(task.title)}
                        isCompleted={task.title === 'Post on Thala' && completedTasks.is_post_on_thala}
                        onTaskSuccess={(message) => {
                            setTaskPopupMessage(message);
                            setShowTaskPopup(true);
                            setTimeout(() => {
                                setShowTaskPopup(false);
                            }, 3000);
                        }}
                        onCoinAnimation={handleShowCoinAnimation}
                    />
                ))}

                <div className="section-title task-list-title">Tasks list</div>
                {taskList.map((task, index) => (
                    <TaskCard
                        key={index}
                        title={task.title}
                        amount={task.amount}
                        url={task.url}
                        onStart={(title, reference) => handleTaskStart(title, reference)}
                        taskType={getTaskType(task.title)}
                        isCompleted={
                            (task.title === 'Follow us on Facebook' && completedTasks.is_follow_FB) ||
                            (task.title === 'Follow us on Instagram' && completedTasks.is_follow_IG) ||
                            (task.title === 'Follow us on X' && completedTasks.is_follow_X) ||
                            (task.title === 'Join our TG Channel' && completedTasks.is_join_tg_channel) ||
                            (task.title === 'Download and Sign Up for Thala' && completedTasks.is_register_thala)
                        }
                        onTaskSuccess={(message, icon) => {
                            setTaskPopupMessage(message);
                            setShowTaskPopup(true);
                            setPopupIcon(icon);
                            setTimeout(() => {
                                setShowTaskPopup(false);
                            }, 3000);
                        }}
                        onCoinAnimation={handleShowCoinAnimation}
                    />
                ))}
            </div>

            <div className={getModalClassName()}>
                <div className="task-modal-content">
                    <img src={closeIcon} alt="close" className="task-modal-close" onClick={handleCloseModal} />
                    {getModalContent()}
                </div>
            </div>
            <audio ref={audioRef} src={taskSound} />
            {showTaskPopup && (
                <div className={`task-popup ${showTaskPopup ? 'show' : ''}`}>
                    <div className="popup-content">
                        <img src={popupIcon} alt="Icon" className="popup-icon" />
                        <span className="popup-text">{taskPopupMessage}</span>
                        <div style={{ flex: 1 }}></div>
                        <img src={closeIcon} alt="Close Icon" className="popup-close" onClick={() => setShowTaskPopup(false)} />
                    </div>
                </div>
            )}
            {showCoinAnimation && (
                <CoinAnimation trigger={showCoinAnimation} coinCount={100} duration={1000} />
            )}
        </div>
    );
};

export default Earn;