import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import './App.css';
import { isTMA, retrieveLaunchParams } from '@tma.js/sdk';
import LoadingAnimation from './LoadingAnimation';
import { MusicProvider } from './music_provider';
import { GameProvider } from './pages/game/game_provider';
import config from './config';

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [loginAttempted, setLoginAttempted] = useState(false);
  const startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  useEffect(() => {
    const checkTelegram = async () => {
      if (!loginAttempted && isTMA()) {
        setLoginAttempted(true);
        try {
          const { initDataRaw } = retrieveLaunchParams();
          const TG_LOGIN_URL = `https://${config.apiUrl}telegram/users/tg_login`;
          const response = await fetch(TG_LOGIN_URL, {
            method: 'POST',
            headers: {
              Authorization: `tma ${initDataRaw}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              invite_code: startParam
            })
          });
  
          if (response.ok) {
            const data = await response.json();
            console.log('Login successful:', data);
  
            localStorage.setItem('token', data.result.token);
            localStorage.setItem('userInfo', JSON.stringify(data.result.telegram));
          } else {
            console.log('Login failed:', response.statusText);
          }
        } catch (error) {
          console.log('Error:', error.message);
        } finally {
          window.Telegram.WebApp.expand();
        }
      } else {
        console.log('Not opened from Telegram or login already attempted');
      }
      setPageLoaded(true);
    };
  
    checkTelegram();
  }, [startParam, loginAttempted]);

  useEffect(() => {
    if (pageLoaded && animationComplete) {
      setLoading(false);
    }
  }, [pageLoaded, animationComplete]);

  useEffect(() => {
    const preventCollapse = (event) => {
      if (window.scrollY === 0) {
        window.scrollTo(0, 1);
      }
    };

    document.addEventListener("touchstart", preventCollapse);

    return () => {
      document.removeEventListener("touchstart", preventCollapse);
    };
  }, []);

  const handleAnimationComplete = () => {
    if (pageLoaded) {
      setLoading(false);
    } else {
      setAnimationComplete(true);
    }
  };

  if (loading) {
    return <LoadingAnimation onComplete={handleAnimationComplete} />;
  }

  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh', overflow: 'hidden' }}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={
              <GameProvider>
                <Home />
              </GameProvider>
            } />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

const App = () => (
  <MusicProvider>
    <AppContent />
  </MusicProvider>
);

export default App;
